<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="商品管理" name="first">
				<el-container style="height: calc(100vh - 183px); border: 1px solid #eee;width: 100%;">
					<el-aside width="200px" style="background-color: rgb(238, 241, 246)">
						<el-menu :default-openeds="['1', '3']" mode="vertical">
							<el-menu-item @click="actmenu(0)" index="0">
								全部分类
							</el-menu-item>
							<el-menu-item @click="actmenu(item.id)" v-for="(item, index) in catelist" :key="item.id"
								:index="index">
								<div style="display: flex;align-items: center;justify-content: center;">
									<div style="flex: 1;">{{item['name']}}</div><em class="u-em">1</em>
								</div>
							</el-menu-item>
						</el-menu>
					</el-aside>
					<div style="width: 100%;">
						<el-card style="display: none;">
							<el-form :inline="true" :model="form" label-width="auto">
								<el-row>
									<el-col :span="6">
										<el-form-item label="测试姓名">
											<el-input v-model="form.item1" placeholder="item1"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item label="item2">
											<el-input v-model="form.item2" placeholder="item2"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item label="item3">
											<el-input v-model="form.item3" placeholder="item3"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item label="item4">
											<el-input v-model="form.item4" placeholder="item4"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item label="item5">
											<el-input v-model="form.item5" placeholder="item5"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item label="item6">
											<el-input v-model="form.item6" placeholder="item6"></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row type="flex" justify="start">
									<el-col :span="6">
										<el-form-item>
											<el-button type="primary" @click="onSubmit">查询</el-button>
										</el-form-item>
									</el-col>
								</el-row>
							</el-form>
						</el-card>
						<div class="goods-header">
							<div class="tabs clearfix">
								<a href="javascript:;" @click="ontabs(0)" data-filter="0"
									:class="tabs==0?'on':''">全部商品<em> </em></a>
								<a href="javascript:;" @click="ontabs(1)" data-filter="1"
									:class="tabs==1?'on':''">售卖中<em> </em></a>
								<a href="javascript:;" @click="ontabs(2)" data-filter="2"
									:class="tabs==2?'on':''">已下架<em> </em></a>
								<a href="javascript:;" @click="ontabs(3)" data-filter="4"
									:class="tabs==3?'on':''">回收站<em> </em></a>
								<!--<a href="javascript:;" @click="ontabs(4)" data-filter="3">未合并<em> 1</em></a>-->
								<a href="javascript:;" @click="ontabs(5)" data-filter="5"
									:class="tabs==5?'on':''">未同步<em> </em></a>
								<a href="javascript:;" @click="ontabs(6)" data-filter="6"
									:class="tabs==6?'on sell-out-list':'sell-out-list'" style="display: none;">已售罄<em>
										0</em></a>
								<a href="javascript:;" data-filter="7" @click="ontabs(7)"
									:class="tabs==7?'on batch-goods-list':'batch-goods-list'">待完善<em> </em></a>
							</div>
							<div class="search-bar">
								<div class="searchModule">
									<div class="search-bar">
										<a href="javascript:;" class="search-btn" id="js_search"
											style="display: inline;">
											<i class="icon iconfont icon-admin-sousuo"></i>
										</a>
										<input id="js_keyword" name="keyword" autocomplete="off" type="text"
											placeholder="商品名称/条码/货号">
										<img src="https://cloud.maiyatian.com/product/icon/g-cha.png" alt=""
											style="display: none;">
									</div>

								</div>

							</div>
						</div>
						<el-card style="margin-top: 10px;position: relative;">
							<el-table row-key="id"
								:header-cell-style="{fontSize: '14px', backgroundColor: '#f4f4f5',color:'#333'}"
								default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
								height="calc(100vh - 300px)" :data="pagedTableData"
								@selection-change="handleSelectionChange">
								<!--<el-table-column type="expand">
									<template slot-scope="props">
										<div v-for="(item1,ind) in props.row.specs" :key="ind">
											<el-row :gutter="24">
												<el-col :span="3"><span style="color: #fff;">空格</span></el-col>
												<el-col :span="5">{{ item1.name }}</el-col>
												<el-col :span="5">{{ item1.price }}</el-col>
												<el-col :span="5">{{ item1.stock }}</el-col>
												<el-col :span="5">{{ item1.unit }}</el-col>
												<el-col :span="5">{{ item1.unit_val }}</el-col>
												<el-col :span="5">{{ item1.weight }}</el-col>
											</el-row>
										</div>
									</template>
								</el-table-column>-->
								<el-table-column type="selection" width="55">
								</el-table-column>
								<el-table-column prop="img" label="图片" width="70">
									<template slot-scope="scope">
										<div v-if="scope.row.showcz==1">
											<img style="width: 50px;height: 50px;" :src="scope.row.img" />
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="name" label="商品">
								</el-table-column>
								<el-table-column prop="guige" label="规格" width="100"> </el-table-column>
								<el-table-column prop="istb" label="简单商品" width="80">
									<template slot-scope="scope">
										<div>{{scope.row.istb == 1?'是':'否'}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="price" label="价格" width="70"> </el-table-column>
								<el-table-column prop="stock" label="库存" width="70"> </el-table-column>
								<el-table-column prop="istb" label="同步店铺" width="120"> </el-table-column>
								<!-- 其他列 -->
								<el-table-column label="操作" width="120">
									<template slot-scope="scope">
										<div v-if="scope.row.showcz==1">
											<a href="javascript:;" class="optbtn"
												@click="handleEdit(scope.$index, scope.row)">编辑</a>
											<a href="javascript:;" class="optbtn"
												@click="handleTb(scope.$index, scope.row)">同步</a>
											<!--<a href="javascript:;" class="optbtn"
												@click="handleEdit(scope.$index, scope.row)">合并</a>-->
											<el-button size="mini" type="danger"
												@click="handleDelete(scope.$index, scope.row)">删除
											</el-button>
										</div>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
								:page-sizes="[10, 20, 30, 40, 50]" :current-page.sync="pageNo"
								layout="total, sizes, prev, pager, next, jumper" :total="tableData.length">
							</el-pagination>
							<div style="position: absolute;bottom: 23px;right: 50px;z-index: 999;">
								<el-button @click="shangjia(1)" size="mini" round icon="el-icon-upload2">上架</el-button>
								<el-button @click="shangjia(0)" size="mini" round icon="el-icon-download">下架</el-button>
								<el-button @click="tongbu" size="mini" round icon="el-icon-upload">同步</el-button>
								<el-button size="mini" round icon="el-icon-coin">改库存</el-button>
								<el-button @click="addgoods" size="mini" round type="primary" icon="el-icon-plus">创建商品
								</el-button>
							</div>
						</el-card>
					</div>
				</el-container>
			</el-tab-pane>
			<el-tab-pane label="批量操作" name="second">
				<div class="group clearfix">
					<ul>
						<!--                <li>-->
						<!--                    <a href="/multi/excel/">-->
						<!--                        <span>Excel批量导入</span>-->
						<!--                        <p>使用表格模板批量更新</p>-->
						<!--                    </a>-->
						<!--                </li>-->
						<li>
							<a href="#/table/plprice/">
								<span>批量更新价格</span>
								<p>使用多种价格模式修改价格</p>
							</a>
						</li>
						<!--<li>
							<a href="/multi/actprice/">
								<span>批量更新活动价</span>
								<p>使用多种价格模式修改折扣活动价格</p>
							</a>
						</li>-->
						<li>
							<a href="#/table/tongbu">
								<span>批量同步商品</span>
								<p>使用模板模式同步商品</p>
							</a>
						</li>
						<li>
							<a href="#/table/Batchupload">
								<span>批量上传图片</span>
								<p>使用压缩包批量更新商品图片</p>
							</a>
						</li>
						<li>
							<a href="#/table/plkaidian">
								<span>一键搬店</span>
								<p>快速开新店</p>
							</a>
						</li>
						<li>
							<a href="#/table/plkeyword">
								<span>批量关键词</span>
								<p>根据商品名称批量替换关键词</p>
							</a>
						</li>
						<!--<li>
							<a href="/multi/watermark/">
								<span>批量去水印</span>
								<p>图片批量去美团水印</p>

							</a>
						</li>-->

						<li>
							<a href="#/table/plstock">
								<span>批量改库存</span>
								<p>一次修改多个库存</p>
							</a>
						</li>
						<li>
							<a href="#/table/pladd">
								<span>批量新建</span>
								<p>适合创建大量商品</p>
							</a>
						</li>

					</ul>
				</div>

			</el-tab-pane>
			<el-tab-pane label="线上商品" name="Onlineproducts">线上商品</el-tab-pane>
			<el-tab-pane label="任务中心" name="TaskCenter">
				<el-row>
					<el-table :data="taskData" height="calc(100vh - 200px)" border style="width: 100%">
						<el-table-column prop="name" label="名称" width="180">
						</el-table-column>
						<el-table-column prop="dec" label="描述" width="180">
						</el-table-column>
						<el-table-column prop="status" label="状态" width="80">
						</el-table-column>
						<el-table-column prop="jindu" label="进度" width="80">
						</el-table-column>
						<el-table-column prop="result" label="结果">
						</el-table-column>
						<el-table-column prop="date" label="时间" width="200">
						</el-table-column>
						<el-table-column prop="admin" label="操作人" width="80">
						</el-table-column>
					</el-table>
				</el-row>
			</el-tab-pane>
			<el-tab-pane label="商品分类" name="goodscate">商品分类</el-tab-pane>
		</el-tabs>
		<el-drawer :wrapperClosable="false" title="同步店铺" :visible.sync="drawer">
			<div style="padding: 10px;">
				<div>
					已选择{{selnum}}个商品
				</div>
				<div style="padding: 10px 0;">
					<el-input placeholder="搜索店铺名称/编码（多个以,分隔）" v-model="keywords" class="input-with-select">
						<el-button @click="onsearch" slot="append" icon="el-icon-search"></el-button>
					</el-input>
				</div>
				<el-table ref="multipleTable" :data="shopData1" tooltip-effect="dark" style="width: 100%;"
					height="calc(100vh - 230px)" @selection-change="shopSelectionChange">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column prop="type" label="平台" width="120">
						<template slot-scope="scope">
							<div>{{scope.row.type == 1?'饿了么外卖':'饿了么零售'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="店铺名称" show-overflow-tooltip>
					</el-table-column>
				</el-table>
				<div>
					<el-row :gutter="24"
						style="align-items: center;align-items: center;justify-content: center;display: flex;margin: 0px;margin-top: 10px;">
						<el-col :span="17">已选择{{selshopnum}}个店铺</el-col>
						<el-col :span="7">
							<el-button @click="onacttb" type="primary" round>开始同步</el-button>
						</el-col>
					</el-row>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import vm from '@/main';
	import axios from 'axios';
	export default {
		data() {
			return {
				tabs: 0,
				keywords: '',
				drawer: false,
				tableData: [],
				pageNo: 1,
				pageSize: 10,
				categoryId: 0,
				form: {},
				catelist: [{}],
				goodslist: [{}],
				activeName: 'first',
				taskData: [{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				multipleSelection: [],
				shopData1: [{
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				shop_mul_sel: [],
				selshopnum: 0,
				selnum: 0
			}
		},
		created() {
			this.getcatelist();
			this.getgoodslist();
		},
		computed: {
			pagedTableData() {
				return this.tableData.filter(
					(v, i) =>
					i >= (this.pageNo - 1) * this.pageSize &&
					i <= this.pageNo * this.pageSize - 1
				);
			}
		},
		methods: {
			ontabs(ind) {
				this.tabs = ind;
				this.getgoodslist();
			},
			onacttb() {
				if (this.shop_mul_sel.length <= 0) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个店铺'
					});
					return;
				}
				var multipleSelection = this.multipleSelection;
				var shop_mul_sel = this.shop_mul_sel;
				var arrids = [];
				var shopids = [];
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						arrids.push({
							id: item.id
						});
					}
				})
				shop_mul_sel.forEach(function(item, index) {
					if (item.id > 0) {
						shopids.push({
							id: item.id
						});
					}
				})
				this.$confirm('确定执行同步操作嘛？, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.acttb(arrids, shopids);
					console.log("dd")
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
				console.log("dd")
			},
			acttb(goodsids, shopids) {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('shopids', JSON.stringify(shopids))
				formData1.append('goodsids', JSON.stringify(goodsids))
				formData1.append('act', "tongbu")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/synchronization.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					}
					setTimeout(function() {
						loading.close();
					}, 200)
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功'
					});
					this.drawer = false;
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
				console.log("ddd")
			},
			shopSelectionChange(val) {
				this.shop_mul_sel = val;
				this.selshopnum = this.shop_mul_sel.length;
				console.log("this.multipleSelection", this.shop_mul_sel)
			},
			tongbu() {
				var multipleSelection = this.multipleSelection;
				var num = 0;
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						num = num + 1;
					}
				})
				if (num > 0) {
					this.getshoplist();
					this.selnum = num;
					this.drawer = true;
					console.log("dd")
				} else {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个商品'
					});
					return;
				}
			},
			shangjia(zcind) {
				var multipleSelection = this.multipleSelection;
				var num = 0;
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						num = num + 1;
					}
				})
				if (num > 0) {
					this.$confirm('确定执行此操作嘛？, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.setstatus(zcind);
						this.selnum = num;
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
					console.log("dd")
				} else {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个商品'
					});
					return;
				}
			},
			setstatus(zcind) {
				var multipleSelection = this.multipleSelection;
				var arrids = [];
				multipleSelection.forEach(function(item, index) {
					if (item.id > 0) {
						arrids.push({
							id: item.id
						});
					}
				})
				const formData1 = new FormData()
				formData1.append('status', zcind)
				formData1.append('ids', JSON.stringify(arrids))
				formData1.append('act', "setstatus")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/Goods.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					}
					this.$message({
						showClose: true,
						type: 'success',
						message: '操作成功'
					});
					this.getgoodslist();
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
				console.log("ddd")
			},
			onsearch() {
				this.getshoplist();
			},
			getshoplist() {
				const formData1 = new FormData()
				formData1.append('type', 3)
				formData1.append('keywords', this.keywords)
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/getShop.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.shopData1 = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log("this.multipleSelection", this.multipleSelection)
			},
			addgoods() {
				this.$router.push({
					path: "/form/basicForm",
					query: {}
				})
			},
			handleClick() {
				console.log("dds");
			},
			getgoodslist() {
				const loading = this.$loading({
					lock: false,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				const formData1 = new FormData()
				formData1.append('tabs', this.tabs)
				formData1.append('act', "list")
				formData1.append('categoryId', this.categoryId.toString())
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = this.$Apiurl + '/api/Goods.aspx'
				axios.post(url, formData1).then(data => {
					setTimeout(function() {
						loading.close();
					}, 200)
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						var lists = data.data.data;
						lists.forEach(function(item, index) {
							if (item.specs != "") {
								item.specs = JSON.parse(item.specs);
								var s_l = item.specs;
								item.children = [];
								s_l.forEach(function(val, key) {
									if (key > 0) {
										var sdd = {
											"id": 0,
											"name": "",
											"guige": val.name,
											"price": val.price,
											"stock": val.stock,
											"title": val.name,
											"specs": item.specs,
											"showcz": 0,
											"stdCategoryProperty": item.stdCategoryProperty
										};
										item.children.push(sdd);
									} else {
										item.guige = val.name;
										item.price = val.price;
										item.stock = val.stock;
									}

								})
							}
							item["img"] = "";
							if (item.image != "" && item.image != null) {
								var image_arr = JSON.parse(item.image);
								item["img"] = image_arr[0]["url"];
							}
							item["showcz"] = 1;
						})
						console.log('lists', lists)
						this.tableData = lists;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			getcatelist() {
				const formData1 = new FormData()
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = this.$Apiurl + '/api/addGoodsCategory.aspx'
				axios.post(url, formData1).then(data => {
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.catelist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			actmenu(id) {
				this.categoryId = id;
				this.getgoodslist();
				console.log(id)
			},

			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.pageNo = 1;
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
			},
			onSubmit() {
				console.log(this.form);
			},
			handleTb(index, row) {
				console.log("sdss", row);
				// 编辑操作
			},
			handleEdit(index, row) {
				console.log("sdss", row);
				this.$router.push({
					path: '/form/basicForm?id=' + row.id,
					query: {
						id: row.id
					}
				});
				// 编辑操作
			},
			handleDelete(index, row) {
				this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const formData1 = new FormData()
					formData1.append('act', "delete")
					formData1.append('id', row.id)
					formData1.append('token', this.$Cookies.get("vue_elm_token"))
					const url = this.$Apiurl + '/api/Goods.aspx'
					axios.post(url, formData1).then(data => {
						vm.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getgoodslist();
						console.log('data', data)
					}).catch(response => {
						console.log(response)
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
				// 删除操作
			},
			goToAboutPage() {
				this.$router.push({
					path: '/form/basicForm?param=ssasasa',
					query: {
						title: '你好'
					}
				});
			}
		}
	}
</script>

<style>
	ol,
	ul {
		padding-left: 0;
		list-style-type: none;
	}

	.group li {
		position: relative;
		float: left;
		width: 300px;
		height: 100px;
		background-color: #f9f9fb;
		text-align: left;
		line-height: 2;
		margin-right: 20px;
		margin-bottom: 20px;
		border-radius: 5px;
		cursor: pointer;
	}

	.group li a {
		display: block;
		padding: 20px 15px;
	}

	.group li span {
		font-weight: 500;
		line-height: 2;
	}

	.group li p {
		font-size: 14px;
		color: #999;
	}

	li {
		list-style: none;
	}

	.u-em {
		color: #666;
		border-radius: 10px;
		text-align: center;
		font-size: 12px;
		height: 18px;
		line-height: 18px;
		min-width: 18px;
		background: rgba(239, 239, 244, .65);
	}

	.optbtn {
		position: relative;
		padding: 0 2px 0 2px;
		line-height: 28px;
		color: #1E2330;
		display: inline-block;
		vertical-align: top;
		margin: 0 6px 2px 0;
		height: 28px;
		color: #2daed6;
	}

	.goods-header {
		position: relative;
	}

	.goods-list .tabs {
		margin-bottom: 15px;
	}

	.goods-header .tabs {
		display: flex;
		flex-wrap: nowrap;
	}

	.tabs {
		margin-top: 20px;
		border-bottom: 1px solid #f5f5f5;
	}

	.goods-header .tabs a {
		white-space: nowrap;
	}

	.tabs a {
		position: relative;
		float: left;
		padding: 0 20px;
		height: 34px;
	}

	a {
		color: #333;
		text-decoration: none;
	}

	.goods-header .search-bar {
		position: absolute;
		right: 0;
		left: auto;
		top: 0px;
		margin: 0;
		z-index: 20;
	}

	.search-bar input {
		display: block;
		padding: 6px 35px 6px 12px;
		border: 1px solid #eee;
		border-radius: 7px;
	}

	.tabs a.on:after {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -15px;
		width: 30px;
		height: 2px;
		background: #2daed6;
		content: "";
	}

	.search-bar .icon {
		top: 0px;
		right: 5px;
		display: inline-block;
		margin-right: 0 !important;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		font-size: 14px;
		line-height: 30px;
		cursor: pointer;
	}

	.top-menu,
	.search-bar .icon {
		position: absolute;
		text-align: center;
	}

	em {
		font-style: normal;
	}

	*,
	:after,
	:before {
		box-sizing: border-box;
	}
</style>
